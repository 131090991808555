<template>
  <div>
    <pdf
      ref="pdf"
      id="pdfPrint"
      :key="i"  
      :src="url" 
      :page="i"
      @page-loaded="pageLoaded($event)" 
      ></pdf>
  </div>
</template>
<script>
import pdf from 'vue-pdf'
export default {
	components:{
		pdf
  },
  data(){
		return {
				url:"",
        numPages:null, // pdf 总页数
		}
  },
  created(){
    let facePdf = sessionStorage.getItem('facePdf');
    this.url = facePdf
    this.getNumPages()
  },
  mounted(){
    if(window.matchMedia){
      let mediaQueryList = window.matchMedia('print');
      mediaQueryList.addListener((mql) => {
        if(mql.matches){
          console.log("打印之前")
        }else{
          // window.opener = null;    
          // window.close(); 
        }

      })
    }
  },
  methods:{
    pageLoaded(e){
      console.log(e)
      // this.$refs.pdf.print()
      setTimeout(() => {
        window.print();
      },500)
      
    },
    getNumPages(){
      let loadingTask = pdf.createLoadingTask(this.url)
      loadingTask.promise.then(pdf => {
        this.numPages = pdf.numPages
        // console.log(this.numPages, 'numpages')
      }).catch(err => {
        console.log('pdf 加载失败', err)
      })
    }
  }
}
</script>
<style>
@media print {
  @page {
    size: auto; /* auto is the initial value */
    margin: 3mm; /* this affects the margin in the printer settings */
  }

  html {
    background-color: #ffffff;
    margin: 0px; /* this affects the margin on the html before sending to printer */
  }

  body {
    border: solid 1px rgba(255, 255, 255, 0);
    margin: 10mm 15mm 10mm 15mm;
    /* margin you want for the content */
    height:auto;
  }
  #pdfPrint {
    margin: 0 1em;
  }
}
</style>